import { useParams } from '@solidjs/router';
import { Component, createSignal, For, onMount, Show } from 'solid-js';
import { ADD_EMPLOYMENT, ANNUAL_INCOME } from '~/assets/strings';
import LabeledTextInput from '~/components/common/Inputs/LabeledTextInput';
import ActivityIndicator from '~/components/common/activity-indicator/ActivityIndicator';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { useLocalization } from '~/hooks/useLocalization';
import { StepFooter } from '~/pages/rental-application/steps/StepFooter';
import { PresentableEmploymentInformation, RentalApplicationPresenter } from '~/presenters/RentalApplicationPresenter';
import { RentalApplicationSteps } from '~/types/RentaApplicationSteps';
import { RemoveEmploymentHistoryErrorUseCase } from '~/use-cases/rental-applications/application-steps/removeEmploymentHistoryUseCase';
import { UpdateRentalApplicationInfoUseCase } from '~/use-cases/rental-applications/application-steps/updateRentalApplicationInfoUseCase';
import { ValidateRentalApplicationInfoUseCase } from '~/use-cases/rental-applications/application-steps/validateRentalApplicationInfoUseCase';
import { GetRentalApplicationUseCase } from '~/use-cases/rental-applications/getRentalApplicationUseCase';
import { GoToNextStepUseCase } from '~/use-cases/rental-applications/goToNextStepUseCase';
import { GoToPrevStepUseCase } from '~/use-cases/rental-applications/goToPrevStepUseCase';
import { EmploymentHistoryItem } from './EmploymenHistoryItem';

export const EmploymentIncomeStep: Component = () => {
  const { t } = useLocalization();
  const params = useParams();
  const [isGoingToNextStep, setIsGoingToNextStep] = createSignal(false);

  const { model: presentableRentalApplication } = usePresenter(RentalApplicationPresenter);
  const { execute: getRentalApplication, isLoading: isLoadingApplication } = useUseCase(GetRentalApplicationUseCase);
  const { execute: updateRentalApplication } = useUseCase(UpdateRentalApplicationInfoUseCase);
  const { execute: goToPrevStep } = useUseCase(GoToPrevStepUseCase);
  const { execute: goToNextStep, didSucceed: didSucceedGoingNext } = useUseCase(GoToNextStepUseCase);
  const { execute: validateRentalApplication, didSucceed: didSucceedValidation } = useUseCase(ValidateRentalApplicationInfoUseCase);
  const { execute: removeEmploymentlHistoryError } = useUseCase(RemoveEmploymentHistoryErrorUseCase);

  const onUpdateAnnualIncome = (income: string) => {
    updateRentalApplication({
      annualIncome: income,
    });
    validateRentalApplication('annualIncome');
  };

  const addNewJobHistory = () => {
    const history = [...(presentableRentalApplication()?.employmentHistory ?? []), {}];
    updateRentalApplication({
      employmentHistory: history,
    });
  };

  const onRemoveJobHistory = (index: number) => {
    const history = presentableRentalApplication()?.employmentHistory?.filter((_, i) => i !== index);
    updateRentalApplication({
      employmentHistory: history,
    });
    removeEmploymentlHistoryError(index);
  };

  const onUpdateJobHistoryItem = (historyItem: PresentableEmploymentInformation, index: number) => {
    const history = (presentableRentalApplication()?.employmentHistory ?? []).map((item, i) => (i === index ? historyItem : item));
    updateRentalApplication({
      employmentHistory: history,
    });
  };

  const onPrev = async () => {
    await goToPrevStep({
      currentStep: RentalApplicationSteps.WORK_HISTORY,
      credentials: { id: params.id, password: params.password },
    });
  };

  const onNext = async () => {
    await validateRentalApplication();
    if (!didSucceedValidation() || presentableRentalApplication()?.errors?.hasEmploymentHistoryError) {
      return;
    }
    setIsGoingToNextStep(true);
    await goToNextStep({
      currentStep: RentalApplicationSteps.WORK_HISTORY,
      credentials: { id: params.id, password: params.password },
    });
    if (!didSucceedGoingNext()) {
      setIsGoingToNextStep(false);
    }
  };

  const onFocus = (event: FocusEvent) => {
    (event.currentTarget as HTMLInputElement).select();
  };

  onMount(async () => {
    await getRentalApplication({ id: params.id, password: params.password });
  });

  return (
    <Show
      when={!isLoadingApplication() && !isGoingToNextStep()}
      fallback={
        <div class="flex h-section3">
          <ActivityIndicator />
        </div>
      }>
      <div class="scrollbar flex h-section3  grow flex-col overflow-y-auto bg-white p-5 pb-3">
        <LabeledTextInput
          id="annualIncome"
          class="w-1/3"
          label={t(ANNUAL_INCOME)}
          error={presentableRentalApplication()?.errors?.annualIncomeError}
          value={presentableRentalApplication()?.annualIncome}
          onChange={onUpdateAnnualIncome}
          onFocus={onFocus}
          placeholder="$1,200.00"
          required
        />
        <hr class="my-6" />
        <For each={presentableRentalApplication()?.employmentHistory}>
          {(record, index) => (
            <EmploymentHistoryItem
              index={index()}
              history={record}
              onUpdate={onUpdateJobHistoryItem}
              onDelete={onRemoveJobHistory}
              errors={presentableRentalApplication()?.employmentHistoryErrors?.[index()]}
            />
          )}
        </For>
        <button id="add-employment" class="mt-4 text-left text-primary-color hover:underline" onClick={addNewJobHistory}>
          + {t(ADD_EMPLOYMENT)}
        </button>
      </div>
      <StepFooter onNext={onNext} onPrevious={onPrev} />
    </Show>
  );
};

export default EmploymentIncomeStep;
